import React, { useRef, useEffect, useState, useContext } from 'react';
import { useForm, Controller, useWatch, SubmitHandler } from 'react-hook-form';
import scopeMatchAPI from 'api/ScopeMatchAPI';
import { ModalOverlay, FormGroup, Input, LoadingPage } from 'components/core';
import PickListContext from 'context/PickListContext';
import MultiSelectAutocomplete from 'screens/PremiumTradeDirectory/components/inputs/MultiSelectAutocomplete';
import { toast } from 'react-toastify';

interface IProps {
    isOpen: boolean;
	product?: PTDProduct,
    onRequestClose: () => void;
    addNewProduct: (fields: Partial<PTDProductRequest>) => Promise<boolean>;
	editProduct: (id: number, fields: Partial<PTDProductRequest>) => Promise<boolean>;
}

interface ProductSpec {
    productItems: { id: string; label: string }[];
    pickListItems: { id: string; label: string }[];
    speckListItems: string[];
}

interface FormValues {
    name: string;
    productSpec: ProductSpec;
}

const ERROR_MESSAGES = {
	VALID_PRODUCT_SPEC: 'You need to select valid product specification',
	ONLY_ONE_REQUIRED: 'Only one required',
	ONE_FIELD_REQUIRED: 'One field of the two is required',
	CATALOG_LOAD_ERROR: 'Could not load the product catalog'
};

// Functions before they are used
const validProductSpec = (inputValue: string, productSpecInput: ProductSpec): string | true => {
	if (inputValue && inputValue !== '' && productSpecInput.productItems.length === 0) {
		return ERROR_MESSAGES.VALID_PRODUCT_SPEC;
	}
	return true;
};

const onlyOneRequired = (productNameInput: string, productSpecInput: ProductSpec, inputValue: string): string | true => {
	if ((productNameInput !== '') && (productSpecInput.productItems.length > 0) && inputValue === '') {
		return ERROR_MESSAGES.ONLY_ONE_REQUIRED;
	}
	if (!(productNameInput !== '') && !(productSpecInput.productItems.length > 0) && inputValue === '') {
		return ERROR_MESSAGES.ONE_FIELD_REQUIRED;
	}
	return true;
};

function AddProductModal({ isOpen, product, onRequestClose, addNewProduct, editProduct }: IProps) {
	const isMounted = useRef(true);

	const PickListCxt = useContext(PickListContext);
	const [productSpecsList, setProductSpecsList] = useState<ProductTypeDTO[] | undefined>(undefined);
	const [suggestions, setSuggestions] = useState<SuggestionsType>({});
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [inputValue, setInputValue] = useState<string>('');

	const methods = useForm<FormValues>({
		defaultValues: {
			productSpec: {
				productItems: [],
				speckListItems: [],
				pickListItems: []
			},
			name: ''
		}
	});

	const productNameInput = useWatch({ control: methods.control, name: 'name' });
	const productSpecInput = useWatch({ control: methods.control, name: 'productSpec' });

	methods.register('name', { validate: () => onlyOneRequired(productNameInput, productSpecInput, inputValue) });
	methods.register('productSpec', { validate: {
		validProductSpec: () => validProductSpec(inputValue, productSpecInput),
		onlyOneRequired: () => onlyOneRequired(productNameInput, productSpecInput, inputValue)
	} });

	useEffect(() => {
		isMounted.current = true;
		return () => { isMounted.current = false; };
	}, []);

	useEffect(() => {
		if (product && product.isFromSpec) {
			methods.setValue('productSpec', {
				productItems: [{id: product?.productsTypePickListItemId?.toString() || '', label: product?.name || ''}],
				speckListItems: product?.productSpecs?.map(spec => spec.speckListItemId.toString()) || [],
				pickListItems: product?.productSpecs?.map(spec => ({
					id: spec.pickListItemId.toString(),
					label: spec.pickListItemName
				})) || [],
			});
		}

		if (product && !product.isFromSpec) {
			methods.setValue('name', (!product?.isFromSpec && product?.name) || '');
		}

	}, [methods, product]);

	useEffect(() => {
		const fetchSpecs = async () => {
			try {
				const productTypeListResult = await scopeMatchAPI.product.GetProductTypeList();
				if (productTypeListResult && isMounted.current) {
					setProductSpecsList(productTypeListResult.filter(p => p.productSpecCodes?.length !== 0));
					setIsLoading(false);
				}
			} catch (error) {
				if (isMounted.current) {
					toast.error(ERROR_MESSAGES.CATALOG_LOAD_ERROR);
				}
			}
		};
		fetchSpecs();
	}, []);

	useEffect(() => {
		const getOptions = (productSpecs: ProductTypeDTO): string[][] =>
			(productSpecs.productSpecCodes || []).map(spec => {
				const pickListType = PickListCxt.pickListTypes.find(x => x.code === spec.productSpecCode);
				return PickListCxt.pickListItems
					.filter(x => x.pickListType?.code === pickListType?.code)
					.map(p => p.code || '') || [];
			});

		if (productSpecsList) {
			const res: SuggestionsType = productSpecsList.reduce((prev, curr) => {
				const currSecs = getOptions(curr);
				return { ...prev, [curr.productTypeCode as string]: currSecs };
			}, {} as SuggestionsType);

			setSuggestions(res);
		}
	}, [PickListCxt.pickListItems, PickListCxt.pickListTypes, productSpecsList]);

	const submitForm: SubmitHandler<FormValues> = async (fields) => {
		setIsLoading(true);

		const payload = {
			name: fields.name,
			productsTypePickListItemIds: fields.productSpec.productItems.map(spec => parseInt(spec.id)),
			speckListItemIds: fields.productSpec.speckListItems.map(id => parseInt(id)),
			pickListItemIds: fields.productSpec.pickListItems.map(spec => parseInt(spec.id))
		};

		if (fields.productSpec.productItems.length > 0 || fields.name !== '') {
			if (product) {
				// Edit product if it's being passed
				await editProduct(product.id, payload);
			} else {
				// Add new product
				await addNewProduct(payload);
			}

			if (isMounted.current) {
				methods.reset();
				onRequestClose();
				setIsLoading(false);
			}
		} else {
			if (isMounted.current) {
				setIsLoading(false);
			}
		}
	};

	const close = () => {
		methods.reset();
		onRequestClose();
	};

	if (isLoading) {
		return <LoadingPage />;
	}

	return (
		<ModalOverlay
			isOpen={isOpen}
			modalSize="modal-dialog-centered"
			onRequestClose={close}
			headerChildren={product ? 'Edit Product' : 'Add New Product'}
			confirmButtonChildren={product ? 'Save Changes' : 'Submit'}
			cancelButtonChildren="Cancel"
			confirmButtonAction={methods.handleSubmit(submitForm)}
			cancelButtonAction={close}
			hideCloseButton={true}
		>
			<Controller
				control={methods.control}
				name="productSpec"
				render={({ field, fieldState }) =>
					<FormGroup label="Select Product Specifications" field={field} fieldState={fieldState}>
						<MultiSelectAutocomplete
							suggestions={suggestions}
							id="productSpec"
							name={field.name}
							value={[
								...field.value.productItems?.map(v => v.label) || [],
								...field.value.pickListItems?.map(i => i.label) || []
							]}
							onChangeValue={(values: ProductSpec) => methods.setValue('productSpec', values)}
							onChangeInputValue={(value) => {
								setInputValue(value);
								// Debounce the validation triggers
								setTimeout(() => {
									methods.trigger('name');
									methods.trigger('productSpec');
								}, 300);
							}}
							error={!!fieldState.error?.message}
							disabled={productNameInput !== ''}
							categoryEdit={product?.isFromSpec ? {id: product?.productsTypePickListItemId?.toString() || '', label: product?.name || ''} : undefined}
							selectedItemsEdit={product?.productSpecs?.map(spec => ({
								itemId: spec.pickListItemId.toString(),
								label: spec.pickListItemName,
								typeId: spec.speckListItemId.toString()
							}))}
						/>
					</FormGroup>
				}
			/>

			<div className="divider">OR</div>

			<Controller
				control={methods.control}
				name="name"
				render={({ field, fieldState }) =>
					<FormGroup label="Write Product Name" field={field} fieldState={fieldState}>
						<Input
							id="name"
							placeholder={inputValue !== '' || productSpecInput.productItems.length > 0 ? ERROR_MESSAGES.ONLY_ONE_REQUIRED : 'Write a name'}
							name={field.name}
							value={field.value}
							onChange={e => {
								field.onChange(e);
								// Debounce the validation triggers
								setTimeout(() => {
									methods.trigger('name');
									methods.trigger('productSpec');
								}, 300);
							}}
							error={!!fieldState.error?.message}
							disabled={inputValue !== '' || productSpecInput.productItems.length > 0}
						/>
					</FormGroup>
				}
			/>
		</ModalOverlay>
	);
}

export default AddProductModal;
