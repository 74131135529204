import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';
import SquarePill from '../pills/SquarePill';
import PickListContext from 'context/PickListContext';
import useBreakpointPills from 'utils/useBreakpointPills';

interface IProps {
	id: number | undefined;
	resultLocations: string[] | undefined;
	requestLocations: string[] | undefined;
	orgType?: string;
	resultsPage: boolean | undefined;
}

const BusinessScopeLocations: React.FC<PropsWithChildren<IProps>> = (props: IProps) => {
	const [showMore, setShowMore] = React.useState(false);
	const truncateNumOfPills = useBreakpointPills();

	const picklistCxt = React.useContext(PickListContext);
	const picklistItems = picklistCxt.pickListItems;
	const checkMatchedLocation = (location: any) => {
		return props.resultLocations?.includes(location);
	};

	const hasMatches = props.requestLocations && props.requestLocations.some(checkMatchedLocation);

	let locationType;
	if (props.orgType !== undefined) {
		if (props.orgType === 'distributor') {
			locationType = 'Manufacturing Locations';
		} else if (props.orgType === 'manufacturer') {
			locationType = 'Distributing Locations';
		} else {
			locationType = 'Preferred Locations';
		}
	} else {
		locationType = 'Preferred Locations';
	}

	const toggleShowMore = () => {
		setShowMore(!showMore);
	};

	return (
		<div>
			<p className={classNames('mt-2 pt-3 mb-1 fw-bold', !hasMatches && props.resultsPage === false && 'text-gray-600')}>{locationType}</p>
			<p className={classNames('mb-3', !hasMatches && props.resultsPage === false && 'text-gray-600')}>
				{props?.requestLocations?.slice(0, showMore ? props.requestLocations?.length : truncateNumOfPills).map((locations, lockey) => {
					if (checkMatchedLocation(locations)) {
						return (
							<SquarePill key={`man-location-${props.id}-${lockey}`} className="me-2">
								{picklistItems.find(x => x.code === locations)?.description}
							</SquarePill>
						);
					}
					return (
						<span key={`man-location-${props.id}-${lockey}`}
							className={classNames('mb-1 ms-1 me-2 d-inline-block', props.resultsPage === false && 'text-gray-600')}>
							{picklistItems.find(x => x.code === locations)?.description}
							{props?.requestLocations !== undefined && (
								lockey !== props?.requestLocations.length - 1 ? ',' : ' '
							)}
						</span>
					);
				})

				/* Conditionally render three dots when showing less */}
				{!showMore && props.requestLocations && props.requestLocations.length > truncateNumOfPills && (
					<span className="fw-bold align-self-end">...</span>
				)}

				<small className="d-block text-end">
					{/* Show More/Show Less Button */}
					{props.requestLocations && props.requestLocations.length > truncateNumOfPills && (
						<button onClick={toggleShowMore} className="btn btn-link p-0">
							{showMore ? 'Show Less' : 'Show More'}
						</button>
					)}
				</small>
			</p>
			<hr />
		</div>
	);
};

export default BusinessScopeLocations;
