import PTDAPIs from 'api/PTDAPIs';
import scopeMatchAPI from 'api/ScopeMatchAPI';
import { LoadingPage } from 'components/core';
import AuthContext from 'context/AuthContext';
import { PTDContext } from 'context/PTDContext';
import React, { ReactNode, useCallback } from 'react';

interface IProps {
	children: ReactNode;
}

function PTDStore(props: IProps) {
	const [isInitializing, setIsInitializing] = React.useState(true);
	const [companyProfile, setCompanyProfile] =
		React.useState<OrganizationDTO | null>(null);
	const { currentUser } = React.useContext(AuthContext);
	const [organizationList, setOrganizationList] = React.useState<
		OrganizationDTO[]
	>([]);
	const [PTDMyLists, setPTDMyLists] = React.useState<PremiumTradeDirectory[]>(
		[]
	);
	const [PTDComanagedLists, setPTDComanagedLists] = React.useState<
		PremiumTradeDirectory[]
	>([]);
	const [PTDProducts, setPTDProducts] = React.useState<PTDProduct[]>([]);
	const [PTDVendors, setPTDVendors] = React.useState<{
		[x: string]: PTDVendor[];
	}>({});
	const [PTDAccess, setPTDAccess] = React.useState<PTDAccess[]>([]);
	const [defaultVendorType, setDefaultVendorType] =
		React.useState<VendorType>('Manufacturer');
	const [PTDs, setPTDs] = React.useState<PTD[]>([]);

	React.useEffect(() => {
		const controller = new AbortController();
		const signal = controller.signal;

		async function fetchPTDs(orgDetailsResults: OrganizationDTO) {
			const results: PremiumTradeDirectory[] | null =
				await PTDAPIs.premiumTradeDirectories.GetPremiumTradeDirectories(
					signal
				);
			setPTDMyLists(
				results?.filter(
					(list) => list.ownedByCompanyId === orgDetailsResults?.id
				) || []
			);
			setPTDComanagedLists(
				results?.filter(
					(list) => list.ownedByCompanyId !== orgDetailsResults?.id
				) || []
			);
			setIsInitializing(false);
		}

		const fetchCompanyProfile = async () => {
			const orgDetailsResults =
				await scopeMatchAPI.organization.GetCompanyProfile(signal);
			setCompanyProfile(orgDetailsResults);
			if (orgDetailsResults) {
				fetchPTDs(orgDetailsResults);
			}
		};

		async function fetchOrganizationList() {
			const results = await scopeMatchAPI.organization.GetOrganizationList(
				signal
			);
			setOrganizationList(results || []);
		}

		if (currentUser) {
			fetchCompanyProfile();
		}
		fetchOrganizationList();

		return () => {
			// cancel the request before component unmounts
			controller.abort();
		};
	}, [currentUser]);

	React.useEffect(() => {
		setDefaultVendorType(
			companyProfile?.organizationType === 'owner' ||
				companyProfile?.organizationType === 'distributor'
				? 'Manufacturer'
				: 'MaterialSupplier'
		);
		companyProfile?.organizationType === 'owner' ||
		companyProfile?.organizationType === 'distributor'
			? setPTDs([
				{ id: '0', name: 'Manufacturers', vendorType: 'Manufacturer' },
				{ id: '1', name: 'Distributors', vendorType: 'Distributor' },
			  ])
			: setPTDs([
				{
					id: '0',
					name: 'Material Suppliers',
					vendorType: 'MaterialSupplier',
				},
				{
					id: '1',
					name: 'Equipment Suppliers',
					vendorType: 'EquipmentSupplier',
				},
			  ]);

		return () => {
			// clear the data before component unmounts
			setPTDs([]);
		};
	}, [companyProfile?.organizationType]);

	const fetchProductsForPTDList = useCallback(async (idPTDList, signal) => {
		//setIsInitializing(true);
		try {
			const result: PTDProductSpecRespone[] =
				await PTDAPIs.premiumTradeDirectories.GetProductsandSpecifications(
					{ premiumTradeDirectoryId: idPTDList },
					signal
				);
			let products: PTDProduct[] = [];
			// Grouping by productId
			const groupedByProductId = result.reduce(
				(group: { [x: number]: PTDProductSpecRespone[] }, item) => {
					const { productId } = item;
					if (!group[productId as number]) {
						group[productId] = []; // Initialize the array if it doesn't exist
					}
					group[productId].push(item); // Push the current item into the array
					return group;
				},
				{}
			);
			Object.keys(groupedByProductId).forEach((productId) => {
				const productSpecsArray = groupedByProductId[parseInt(productId)];
				const productSpecsArrayFiltered = productSpecsArray.filter(
					(prod) => prod.pickListItemName && prod.pickListItemName !== ''
				);

				if (productSpecsArray.length > 0) {
					products.push({
						id: productSpecsArray[0].productId,
						premiumTradeDirectoryId:
							productSpecsArray[0].premiumTradeDirectoryId,
						name: productSpecsArray[0].productName,
						productsTypePickListItemId:
							productSpecsArray[0].productsTypePickListItemId,
						isFromSpec:
							productSpecsArray[0].speckListItemId > 0 ||
							productSpecsArray[0].productsTypePickListItemId > 0,
						productSpecs: productSpecsArrayFiltered.map((r) => ({
							pickListItemId: r.pickListItemId,
							pickListItemName: r.pickListItemName,
							speckListItemId: r.speckListItemId,
							speckListItemName: r.speckListItemName,
						})),
					});
				}
			});
			setPTDProducts(products);
			setPTDVendors({});
			products.map((product) => setVendors(product.id, signal));
		} catch (error) {
			console.error('Error fetching data:', error);
		} finally {
			setIsInitializing(false);
			return true;
		}
	}, []);

	const fetchUserAccessForPTDList = useCallback(async (idPTDList, signal?) => {
		getUserAccessForPTDList(idPTDList, signal);
	}, []);

	const initialState = {
		isInitializing,
		// Profile
		getCompanyProfile,
		// Organization List
		getOrganizationList,
		addOrganization,
		// PTD Vendor Type
		getDefaultVendorType,
		// PTD
		getAllPTDs,
		getSinglePTD,
		// createNewPTD,
		// PTD List
		getPTDMyLists,
		getPTDComanagedLists,
		getSinglePTDList,
		addPTDList,
		updatePTDList,
		deletePTDList,
		// Product
		getPTDProducts,
		fetchProductsForPTDList,
		addProduct,
		updateProduct,
		deleteProduct,
		// Vendor
		getPTDVendors,
		addPTDVendor,
		updatePTDVendor,
		deletePTDVendor,
		// Access
		getPTDAccess,
		fetchUserAccessForPTDList,
		addAccessToListForUser,
		updateAccessToListForUser,
		deleteAccessUser,
	};

	function getCompanyProfile(): OrganizationDTO | null {
		return Object.assign({}, companyProfile);
	}

	function getOrganizationList(): OrganizationDTO[] {
		return organizationList.slice();
	}

	function addOrganization(organization: OrganizationDTO) {
		setOrganizationList((organizationList) => [
			...organizationList,
			organization,
		]);
	}

	function getDefaultVendorType(): VendorType {
		return Object.assign({}, defaultVendorType);
	}

	function getAllPTDs(): PTD[] {
		return PTDs.slice();
	}

	function getSinglePTD(id: string | undefined): PTD | undefined {
		if (id !== undefined) {
			return Object.assign(
				{},
				PTDs.find((ptd) => ptd.id === id)
			);
		} else {
			return undefined;
		}
	}

	// function createNewPTD(newPTD: PTD): void {
	// 	setPTDs(ptds => [...ptds, { id: newPTD.id, name: newPTD.name, vendorType: newPTD.vendorType, myList: [] }]);
	// }

	function getPTDMyLists(): PremiumTradeDirectory[] {
		return PTDMyLists?.slice();
	}

	function getPTDComanagedLists(): PremiumTradeDirectory[] {
		return PTDComanagedLists?.slice();
	}

	function getSinglePTDList(
		idPTDList: number | undefined,
		listKey: string | undefined
	): PremiumTradeDirectory | undefined {
		if (idPTDList === null) {
			return undefined;
		}

		let ptdList: PremiumTradeDirectory | undefined;
		switch (listKey) {
		case 'myList':
			ptdList = PTDMyLists.find((list) => list.id === idPTDList);
			break;
		case 'comanagedList':
			ptdList = PTDComanagedLists.find((list) => list.id === idPTDList);
			break;
		default:
			return undefined;
		}

		return ptdList ? { ...ptdList } : undefined;
	}

	function addPTDList(newList: PremiumTradeDirectory): void {
		setPTDMyLists((lists) => [...lists, newList]);
	}

	function updatePTDList(
		newList: PremiumTradeDirectory,
		idPTDList: number
	): void {
		setPTDMyLists((prevLists) => {
			return prevLists.map((list) =>
				list.id === idPTDList ? { ...newList } : list
			);
		});
	}

	function deletePTDList(idPTDList: number | null) {
		setPTDMyLists((prevLists) =>
			prevLists.filter((list) => list.id !== idPTDList)
		);
	}

	function getPTDProducts(): PTDProduct[] {
		return PTDProducts?.slice();
	}

	function addProduct(newProduct: PTDProduct) {
		setPTDVendors((vendors) => ({ ...vendors, [newProduct.id]: [] }));
		setPTDProducts((products) => [...products, newProduct]);
	}

	function updateProduct(editProduct: PTDProduct): void {
		setPTDProducts((products) => {
			const temp = products.map((product) =>
				product.id === editProduct.id ? { ...editProduct } : product
			);
			return temp;
		});
	}

	function deleteProduct(idPTDProduct: number) {
		setPTDProducts((prevProducts) =>
			prevProducts.filter((product) => product.id !== idPTDProduct)
		);
	}

	async function setVendors(
		idPTDProduct: number,
		signal?: AbortSignal
	): Promise<void> {
		try {
			const result: PTDVendor[] =
				await PTDAPIs.premiumTradeDirectories.GetVendors(
					{ productId: idPTDProduct },
					signal
				);
			setPTDVendors((vendors) => ({ ...vendors, [idPTDProduct]: result }));
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	}

	function getPTDVendors(): { [x: string]: PTDVendor[] } {
		return Object.assign({}, PTDVendors);
	}

	function addPTDVendor(newVendor: PTDVendor, idPTDProduct: number) {
		setPTDVendors((vendors) => {
			const temp = Object.assign({}, vendors);
			if (temp[idPTDProduct]) {
				temp[idPTDProduct].push(newVendor);
			} else {
				temp[idPTDProduct] = [newVendor];
			}
			return temp;
		});
	}

	function updatePTDVendor(newVendor: PTDVendor, idPTDProduct: number) {
		setPTDVendors((vendors) => {
			const temp = Object.assign({}, vendors);
			if (temp[idPTDProduct]) {
				temp[idPTDProduct] = temp[idPTDProduct].map((vendor) =>
					vendor.id === newVendor.id ? { ...newVendor } : vendor
				);
			} else {
				temp[idPTDProduct] = [newVendor];
			}
			return temp;
		});
	}

	function deletePTDVendor(idPTDProduct: number, idPTDVendor: number) {
		setPTDVendors((vendors) => {
			const temp = Object.assign({}, vendors);
			temp[idPTDProduct] = temp[idPTDProduct].filter(
				(vendor) => vendor.id !== idPTDVendor
			);
			return temp;
		});
	}

	async function getUserAccessForPTDList(
		idPTDList: number,
		signal?: AbortSignal
	) {
		//setIsInitializing(true);
		try {
			const result: PTDAccess[] =
				await PTDAPIs.premiumTradeDirectories.GetUserAccessForList(
					{ premiumTradeDirectoryId: idPTDList },
					signal
				);
			setPTDAccess(result);
			//setIsInitializing(false);
		} catch (error) {
			console.error('Error fetching data:', error);
			//setIsInitializing(false);
		}
	}

	function getPTDAccess(): PTDAccess[] {
		return PTDAccess?.slice();
	}

	function addAccessToListForUser(newAccess: PTDAccess) {
		setPTDAccess((access) => [...access, newAccess]);
	}

	function updateAccessToListForUser(newAccess: PTDAccess) {
		setPTDAccess((prevAccess) => {
			return prevAccess.map((access) =>
				access.id === newAccess.id ? { ...newAccess } : access
			);
		});
	}

	function deleteAccessUser(idAccessUser: number) {
		setPTDAccess((prevAccess) =>
			prevAccess.filter((access) => access.id !== idAccessUser)
		);
	}

	return (
		<PTDContext.Provider value={initialState}>
			{initialState.isInitializing ? <LoadingPage /> : props.children}
		</PTDContext.Provider>
	);
}

export default PTDStore;
