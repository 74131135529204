import React, { useContext } from 'react';

interface IState {
	isInitializing: boolean,
	// Profile
	getCompanyProfile: () => OrganizationDTO | null,
	// Organization List
	getOrganizationList: () => OrganizationDTO[],
	addOrganization: (organization: OrganizationDTO) => void,
	// PTD Vendor Type
	getDefaultVendorType: () => VendorType,
	// PTD
	getAllPTDs: () => PTD[],
	getSinglePTD: (id: string | undefined) => PTD | undefined,
	// createNewPTD: (newPTD: PTD) => void,
	// PTD List
	getPTDMyLists: () => PremiumTradeDirectory[],
	getPTDComanagedLists: () => PremiumTradeDirectory[],
	getSinglePTDList: (idPTDList: number | undefined, listKey: string | undefined) => PremiumTradeDirectory | undefined,
	addPTDList: (newList: PremiumTradeDirectory) => void,
	updatePTDList: (newList: PremiumTradeDirectory, idPTDList: number) => void,
	deletePTDList: (idPTDList: number | null) => void,
	// Product
	getPTDProducts: () => PTDProduct[],
	fetchProductsForPTDList: (idPTDList: number, signal: AbortSignal) => void,
	addProduct: (newProduct: PTDProduct) => void,
	updateProduct: (editProduct: PTDProduct) => void,
	deleteProduct: (idPTDProduct: number) => void,
	// Vendor
	getPTDVendors: () => {[x: string]: PTDVendor[]},
	addPTDVendor: (newProduct: PTDVendor, idPTDProduct: number) => void,
	updatePTDVendor: (newProduct: PTDVendor, idPTDProduct: number) => void,
	deletePTDVendor: (idPTDProduct: number, idPTDVendor: number) => void,
	// Access
	getPTDAccess: () => PTDAccess[],
	fetchUserAccessForPTDList: (idPTDList: number, signal?: AbortSignal) => void,
	addAccessToListForUser: (newAccess: PTDAccess) => void,
	updateAccessToListForUser: (newAccess: PTDAccess) => void,
	deleteAccessUser: (idAccessUser: number) => void,
}

const initialState: IState = {
	isInitializing: true,
	// Profile
	getCompanyProfile: () => ({} as OrganizationDTO),
	// Organization
	getOrganizationList: () => ([] as OrganizationDTO[]),
	addOrganization: () => {},
	// PTD Vendor Type
	getDefaultVendorType: () => ({} as VendorType),
	// PTD
	getAllPTDs: () => ([] as PTD[]),
	getSinglePTD: () => ({} as PTD),
	// createNewPTD: () => {},
	// PTD List
	getPTDMyLists: () => ([] as PremiumTradeDirectory[]),
	getPTDComanagedLists: () => ([] as PremiumTradeDirectory[]),
	getSinglePTDList: () => ({} as PremiumTradeDirectory),
	addPTDList: () => {},
	updatePTDList: () => {},
	deletePTDList: () => {},
	// Product
	getPTDProducts: () => ([] as PTDProduct[]),
	fetchProductsForPTDList: () => {},
	addProduct: () => {},
	updateProduct: () => {},
	deleteProduct: () => {},
	// Vendor
	getPTDVendors: () => ({} as {[x: string]: PTDVendor[]}),
	addPTDVendor: () => {},
	//getAllVendorsPerList: () => ({} as PTDVendor[]),
	updatePTDVendor: () => {},
	deletePTDVendor: () => {},
	// Access
	getPTDAccess: () => ([] as PTDAccess[]),
	fetchUserAccessForPTDList: () => {},
	addAccessToListForUser: () => {},
	updateAccessToListForUser: () => {},
	deleteAccessUser: () => {},
};

const PTDContext = React.createContext(initialState);

function usePTD() {
	const context = useContext(PTDContext);
	if (context === undefined) {
		throw new Error('PTDContext was used outside the PTDStore');
	}
	return context;
}

export {PTDContext, usePTD};


