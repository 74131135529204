import React from 'react';
import { ReactComponent as IconLocationSecondary } from 'assets/icons/icon-location-secondary.svg';
import PickListContext from 'context/PickListContext';
import Pill from './pills/Pill';
import useBreakpointPills from 'utils/useBreakpointPills';

interface IProps {
	orgHeadOffice?: string;
	orgShippingLocation?: string[];
	orgManufacturingLocation?: OrganizationLocationsDTO[];
	orgType?: string;
	companyProfile?: boolean;
}

const OrgLocationCard = (props: IProps) => {
	const picklistCxt = React.useContext(PickListContext);
	const picklistItems = picklistCxt.pickListItems;
	const countries = picklistItems.filter(
		(item) =>
			item.pickListType?.code === 'Country' &&
			props.orgShippingLocation &&
			item.code &&
			props.orgShippingLocation.includes(item.code)
	);
	const [showMore, setShowMore] = React.useState(false);
	const truncateNumOfPills = useBreakpointPills();

	const toggleShowMore = () => {
		setShowMore(!showMore);
	};

	return (
		<div className="card shadow p-3 mb-2 rounded">
			<div className="d-flex align-items-center">
				<div className="rounded-circle bg-secondary-100 p-2 me-3">
					<IconLocationSecondary />
				</div>
				<h5 className="mb-0">Locations</h5>
			</div>
			<hr />
			{props.orgHeadOffice !== 'undefined' && props.orgHeadOffice !== '' ? (
				<React.Fragment>
					<h6 className="mb-3">Head Office</h6>
					<Pill className="text-primary fw-bold">{props.orgHeadOffice}</Pill>
					<hr />
				</React.Fragment>
			) : (
				''
			)}
			{props?.orgShippingLocation &&
				props.orgShippingLocation.length !== 0 &&
				props.orgType === 'manufacturer' && (
				<React.Fragment>
					<h6>
						{props.orgType === 'manufacturer'
							? 'Recent Export '
							: 'Recent Receiving '}
							Locations
					</h6>
					<div>
						<div className="d-flex flex-wrap align-items-start">
							{countries.length > 0 ? (
								countries
									.slice(0, showMore ? countries.length : truncateNumOfPills)
									.map((shiploc, mlkey) => (
										<Pill
											className="text-primary fw-bold"
											key={`unavailable-locations-${mlkey}`}
										>
											{shiploc.description}
										</Pill>
									))
							) : (
								<React.Fragment>
									{props.companyProfile ? (
										<p className="text-gray-600 fst-italic">None</p>
									) : (
										<Pill className="text-primary fw-bold">None</Pill>
									)}
								</React.Fragment>
							)}

							{/* Conditionally render three dots when showing less */}
							{!showMore && countries.length > truncateNumOfPills && (
								<span className="fw-bold align-self-end">...</span>
							)}
						</div>

						{/* Show More/Show Less Button */}
						{countries.length > truncateNumOfPills && (
							<button onClick={toggleShowMore} className="btn btn-link">
								{showMore ? 'Show Less' : 'Show More'}
							</button>
						)}
					</div>
					<hr />
				</React.Fragment>
			)}
			{props?.orgManufacturingLocation &&
				props.orgManufacturingLocation.length !== 0 && (
				<React.Fragment>
					<h6>
						{props?.orgType === 'distributor'
							? 'Distribution Centre '
							: 'Manufacturing '}
							Locations
					</h6>
					<div>
						<div className="d-flex flex-wrap">
							{props?.orgManufacturingLocation &&
								props.orgManufacturingLocation.length > 0 ? (
									<React.Fragment>
										{props.orgManufacturingLocation
											.slice(
												0,
												showMore
													? props.orgManufacturingLocation.length
													: truncateNumOfPills
											)
											.map((manuloc) => (
												<Pill
													className="text-primary fw-bold"
													key={`full-locations-${manuloc.id}`}
												>
													{manuloc.locationCity}, {manuloc.locationCountry}
												</Pill>
											))}
										{/* Conditionally render three dots when showing less */}
										{!showMore &&
											props.orgManufacturingLocation.length >
												truncateNumOfPills && (
											<span className="fw-bold align-self-end">...</span>
										)}
									</React.Fragment>
								) : (
									<p>
										No{' '}
										{props?.orgType === 'distributor'
											? 'distributing '
											: 'manufacturing '}{' '}
										locations specified
									</p>
								)}
						</div>
						{/* Show More/Show Less Button */}
						{props.orgManufacturingLocation.length > truncateNumOfPills && (
							<button onClick={toggleShowMore} className="btn btn-link">
								{showMore ? 'Show Less' : 'Show More'}
							</button>
						)}
					</div>
				</React.Fragment>
			)}
		</div>
	);
};

export default OrgLocationCard;
