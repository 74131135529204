import React, { PropsWithChildren } from 'react';
import { AccordionProps } from '../../types/interfaces';
import AccordionContent from './AccordionContent';
import AccordionHeader from './AccordionHeader';

function Accordion(props: PropsWithChildren<AccordionProps>) {
	const accordionIndex = props.label.replaceAll(' ', '-');

	return (
		<div className="accordion my-5" id={`accordion-${accordionIndex}`}>
			<div className="accordion-item">
				<AccordionHeader openAccordion={props.defaultOpen} accordionIndex={accordionIndex}>
					{ props.label }
				</AccordionHeader>
				<AccordionContent accordionIndex={accordionIndex}>
					{ props.children }
				</AccordionContent>
			</div>
		</div>
	);
}

export default Accordion;
