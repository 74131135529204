import React from 'react';
import { useLoadGraph, useRegisterEvents, useSigma } from '@react-sigma/core';
import Graph from 'graphology';
import { useContext, useEffect, useMemo } from 'react';
import { EventParams, GraphProps } from '../../types/interfaces';
import { READ_GRAPH } from '../../store/types';
import { StoreContext } from '../../store/context';
import { NodeDisplayData } from 'sigma/types';
import '@react-sigma/core/lib/react-sigma.min.css';
import { GRAPH_COLORS } from 'vna/types/enums';

// Component that loads the graph
export const LoadGraph = ({ data }: GraphProps) => {
	const store = useContext(StoreContext);
	const loadGraph = useLoadGraph();
	const sigma = useSigma();
	const registerEvents = useRegisterEvents();

	if (!store) {
		throw new Error('StoreContext is not available');
	}

	const { dispatch, state } = store;

	const nodeHeight = 50; // Height for each node type
	const minVerticalSpacing = 30; // Reduced spacing to lessen distance

	// Get the dimensions of the Sigma container
	const containerElement = sigma.getContainer();
	const canvasWidth = containerElement?.offsetWidth || window.innerWidth;
	const canvasHeight = containerElement?.offsetHeight || window.innerHeight;

	const xPaddingPercentage = 0.85; // 85% of canvas width for horizontal scaling
	const yPaddingPercentage = 0.55; // 55% of canvas height for vertical scaling

	// Memoize the graph generation to avoid re-creating it on each render
	const newGraph = useMemo(() => {
		const graphInstance = new Graph();

		// Variables to track min/max positions
		let x_min = Infinity;
		let x_max = -Infinity;
		let y_min = Infinity;
		let y_max = -Infinity;

		const lastPositions: { [key: string]: number } = {
			location: 0,
			vendor: 0,
			product: 0,
		};

		data?.nodes.forEach((group, groupIndex) => {
			const parentNode = `parent-${group.parent}`;

			const numChildren = group.children.length;
			// Adjust vertical offsets based on node height
			const locationOffsetY = nodeHeight * Math.max(1, numChildren); // For location nodes
			const vendorOffsetY = locationOffsetY + (nodeHeight * Math.max(1, numChildren)) + minVerticalSpacing; // For vendor nodes
			const productOffsetY = vendorOffsetY + (nodeHeight * Math.max(1, numChildren)) + minVerticalSpacing; // For product nodes
			// Calculate the maximum horizontal spacing based on the number of children
			const horizontalSpacing = Math.max(270, 150 * Math.ceil(numChildren / 2));

			const childPositionsX: number[] = [];

			// Process child nodes to calculate their positions first
			group.children.forEach((child) => {
				let locationNode, vendorNode, productNode;

				if (child.location) {
					locationNode = `location-${child.location}`;

					if (!graphInstance.hasNode(locationNode)) {
						const locationX = lastPositions.location + horizontalSpacing;

						graphInstance.addNode(locationNode, {
							label: child.location,
							size: 10,
							color: GRAPH_COLORS.location,
							x: locationX,
     						y: locationOffsetY,
						});

						lastPositions.location = locationX;
						childPositionsX.push(locationX);
						x_min = Math.min(x_min, locationX);
						x_max = Math.max(x_max, locationX);
						y_min = Math.min(y_min, locationOffsetY);
						y_max = Math.max(y_max, locationOffsetY);
					}
				}

				if (child.vendor !== '') {
					vendorNode = `vendor-${child.vendor}`;

					if (!graphInstance.hasNode(vendorNode)) {
						const vendorX = lastPositions.vendor + horizontalSpacing;

						graphInstance.addNode(vendorNode, {
							label: child.vendor,
							size: 10,
							color: GRAPH_COLORS.vendor,
							x: vendorX,
							y: vendorOffsetY,
						});
						lastPositions.vendor = vendorX;
						childPositionsX.push(vendorX);
						x_min = Math.min(x_min, vendorX);
						x_max = Math.max(x_max, vendorX);
						y_min = Math.min(y_min, vendorOffsetY);
						y_max = Math.max(y_max, vendorOffsetY);
					}
				}

				if (child.product) {
					productNode = `product-${child.product}`;
					if (!graphInstance.hasNode(productNode)) {
						const productX = lastPositions.product + horizontalSpacing;

						graphInstance.addNode(productNode, {
							label: child.product,
							size: 8,
							color: GRAPH_COLORS.product,
							x: productX,
							y: productOffsetY,
						});
						lastPositions.product = productX;
						childPositionsX.push(productX);
						x_min = Math.min(x_min, productX);
						x_max = Math.max(x_max, productX);
						y_min = Math.min(y_min, productOffsetY);
						y_max = Math.max(y_max, productOffsetY);
					}
				}

				// Add edges between nodes
				if (
					graphInstance.hasNode(locationNode) &&
          			graphInstance.hasNode(vendorNode)
				) {
					if (!graphInstance.hasEdge(locationNode, vendorNode)) {
						graphInstance.addEdge(locationNode, vendorNode);
					}
				}

				if (
					graphInstance.hasNode(vendorNode) &&
          			graphInstance.hasNode(productNode)
				) {
					if (!graphInstance.hasEdge(vendorNode, productNode)) {
						graphInstance.addEdge(vendorNode, productNode);
					}
				}
			});

			// Calculate the parent node's position based on child nodes
			const minX = Math.min(...childPositionsX);
			const maxX = Math.max(...childPositionsX);
			const parentX = (minX + maxX) / 2 || groupIndex * 500;

			// Add parent node if it doesn't already exist
			if (!graphInstance.hasNode(parentNode)) {
				graphInstance.addNode(parentNode, {
					label: group.parent,
					size: 15,
					color: GRAPH_COLORS.parent,
					x: parentX,
					y: 0,
				});
			}

			// Add edges between parent and unique locations
			const uniqueLocations = new Set(
				group.children.filter((child) => child.location && child.location !== '').map((child) => `location-${child.location}`)
			);
			uniqueLocations.forEach((locationNodeId) => {
				if (!graphInstance.hasEdge(parentNode, locationNodeId)) {
					graphInstance.addEdge(parentNode, locationNodeId);
				}
			});
		});

		// Traverse through nodes and update edge attributes where necessary
		const nodes = graphInstance.nodes();
		nodes.forEach((node) => {
			const neighbors = graphInstance.neighbors(node);

			if (neighbors.length === 1 && node.includes('product-')) {
				const neighbor = neighbors.join();

				// Check if the edge exists before updating
				if (graphInstance.hasEdge(neighbor, node)) {
					graphInstance.updateEdgeAttributes(neighbor, node, (att) => ({
						...att,
						color: GRAPH_COLORS.bridge,
						size: 3,
					}));
				}
			}
		});

		// Calculate scaling factors for x and y dimensions
		let xRange = x_max - x_min;
		let yRange = y_max - y_min;

		// Fallback for small graphs (e.g., one product, vendor, location)
		if (xRange === 0) {xRange = 500;} // Set a default range for single-node graphs
		if (yRange === 0) {yRange = 500;} // Set a default range for y as well

		// Count the number of location, vendor, and product nodes
		const countLocationNodes = graphInstance.nodes().filter(node => node.includes('location-')).length;
		const countVendorNodes = graphInstance.nodes().filter(node => node.includes('vendor-')).length;
		const countProductNodes = graphInstance.nodes().filter(node => node.includes('product-')).length;

		// Calculate the maximum number of nodes in a category (location, vendor, or product)
		const maxNodesInCategory = Math.max(countLocationNodes, countVendorNodes, countProductNodes);

		// Adjust the xPaddingPercentage based on the number of nodes, adding a buffer if needed
		const dynamicXPaddingPercentage = Math.min(1, xPaddingPercentage + (maxNodesInCategory / 1000)); // Larger node sets need more space

		const scale_x = (dynamicXPaddingPercentage * canvasWidth) / xRange;
		const scale_y = (yPaddingPercentage * canvasHeight) / yRange;

		// // Calculate padding to center the graph
		const padding_x = (canvasWidth - dynamicXPaddingPercentage * canvasWidth) / 2;
		const padding_y = (canvasHeight - yPaddingPercentage * canvasHeight) / 2;

		// Rescale node positions based on canvas size and padding
		const scaledGraph = new Graph();

		graphInstance.forEachNode((node, attributes) => {
			// Fallback for disconnected nodes: if the node has no neighbors, give it a default position
			if (!graphInstance.degree(node)) {
				attributes.x = canvasWidth / 2;
				attributes.y = canvasHeight / 2;
			}

			const scaledX = (attributes.x - x_min) * scale_x + padding_x;
			const scaledY = (attributes.y - y_min) * scale_y + padding_y;

			scaledGraph.addNode(node, {
				...attributes,
				x: scaledX,
				y: scaledY,
			});
		});

		graphInstance.forEachEdge((edge, attributes, source, target) => {
			scaledGraph.addEdge(source, target, attributes);
		});

		return scaledGraph;
	}, [data?.nodes, canvasWidth, canvasHeight]); // Only re-generate the graph if `data`, `canvasWidth`, or `canvasHeight` changes

	useEffect(() => {
		if (newGraph && graphIsDifferent(state.graph, newGraph)) {
			// Only dispatch if the graph content is different
			dispatch({ type: READ_GRAPH, graph: newGraph });
		}
	}, [dispatch, newGraph, state.graph]);

	// Helper function to check if the graph is different
	const graphIsDifferent = (prevGraph: Graph, newGraph: Graph) => {
		// Implement a lightweight comparison between the two graph instances.
		return (
			prevGraph.order !== newGraph.order || prevGraph.size !== newGraph.size
		);
	};

	useEffect(() => {
		// Function to place disconnected nodes
		const placeDisconnectedNodes = (nodes: string[]) => {
			let offsetY = 0; // Start Y offset
			const horizontalSpacing = 200; // Set spacing between disconnected nodes

			nodes.forEach((node) => {
				if (!sigma.getGraph().hasNode(node)) {return;}

				const currentNode = sigma.getGraph().getNodeAttributes(node);
				sigma.getGraph().setNodeAttribute(node, 'x', horizontalSpacing * offsetY);
				sigma.getGraph().setNodeAttribute(node, 'y', currentNode.y); // Keep Y the same as before

				offsetY++; // Increment Y offset for the next node
			});
		};

		loadGraph(newGraph);

		// Identify disconnected nodes
		const disconnectedNodes = sigma.getGraph().nodes().filter((nodeId) => {
			return sigma.getGraph().degree(nodeId) === 0; // Degree 0 means disconnected
		});

		// Call the placeDisconnectedNodes function if there are disconnected nodes
		if (disconnectedNodes.length > 0) {
			placeDisconnectedNodes(disconnectedNodes);
		}
	}, [loadGraph, newGraph, sigma]);

	useEffect(() => {
		sigma.setSetting(
			'nodeReducer',
			(node: string, data): Partial<NodeDisplayData> => {
				const hoveredNode = sigma.getGraph().getNodeAttribute(node, 'highlighted');
				return {
					...data,
					label: hoveredNode ? data.label : '',
				};
			}
		);

		registerEvents({
			enterNode: ({ node }: EventParams) => {
				sigma.getGraph().setNodeAttribute(node, 'highlighted', true);
			},
			leaveNode: ({ node }: EventParams) => {
				sigma.getGraph().setNodeAttribute(node, 'highlighted', false);
			},
		});
	}, [registerEvents, sigma]);

	return null;
};