import React, { useCallback } from 'react';
import TitleBar from 'screens/Components/TitleBar';
import AddProductModal from 'screens/PremiumTradeDirectory/components/modals/AddProductModal';
import DeletePTDVendorModal from 'screens/PremiumTradeDirectory/components/modals/DeletePTDVendorModal';
import classNames from 'classnames';
import DeleteProductModal from 'screens/PremiumTradeDirectory/components/modals/DeleteProductModal';
import PTDAPIs from 'api/PTDAPIs';
import ActionCell from 'screens/PremiumTradeDirectory/components/ActionCell';
import AuthContext from 'context/AuthContext';
import { IconAdd, IconDelete, IconEdit } from 'assets/icons';
import {
	Button,
	DataTable,
	FormGroup,
	Page,
	Select,
	TextArea,
	LoadingPage,
} from 'components/core';
import { Text } from 'screens/ProductCatalog/components/columns';
import { OrganizationSelect } from 'components/scopematch/OrganizationSelect';
import { usePTD } from 'context/PTDContext';
import {
	Controller,
	FieldValues,
	FormProvider,
	useForm,
} from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { getEnumFromVendorStatus } from 'utils/utils';
import { ReactComponent as IconCircleTick } from 'assets/icons/icon-circle-tick.svg';
import { toast } from 'react-toastify';
import { debounce, isString } from 'lodash';
import AsyncSelect from 'react-select/async';
import scopeMatchAPI from 'api/ScopeMatchAPI';

interface Table {
	rowId: number;
	newLine?: boolean;
	manufecId?: number;
	duplicateRow?: number;
}

interface AssociativeTable {
	[key: number]: Table;
}

function PremiumTradeDirectoryListDetails() {
	const params = useParams();
	const id = params.id;
	const idPTDList = Number(params.idPTDList);
	const listName = params.listName;

	// Get PTD
	const {
		getCompanyProfile,
		getOrganizationList,
		addOrganization,
		getSinglePTD,
		getSinglePTDList,
		fetchProductsForPTDList,
		getPTDProducts,
		addProduct,
		updateProduct,
		deleteProduct,
		getPTDVendors,
		addPTDVendor,
		updatePTDVendor,
		deletePTDVendor,
		fetchUserAccessForPTDList,
		getPTDAccess,
	} = usePTD();
	const PTD: PTD | undefined = getSinglePTD(id);
	const PTDList: PremiumTradeDirectory | undefined = getSinglePTDList(
		idPTDList,
		listName
	);
	const PTDProducts = getPTDProducts();
	const PTDVendors = getPTDVendors();
	const PTDAccess = getPTDAccess();

	// General Page Info
	const pageTitle = PTDList
		? PTDList.premiumTradeDirectoryName
		: 'Single Premium Trade Directory';
	const [isLoading, setIsLoading] = React.useState<boolean>(true);
	const { currentUser } = React.useContext(AuthContext);
	const companyProfile = getCompanyProfile();
	const organizationList = getOrganizationList();

	// Access
	const accessForList = PTDAccess
		? PTDAccess.find((acc) => acc.email === currentUser.email)
		: null;

	// Modals
	const [showAddProductModal, setAddProductModal] = React.useState<{
		isOpen: boolean;
		product?: PTDProduct;
	}>({ isOpen: false });
	const [showDeleteProductModal, setDeleteProductModal] = React.useState<{
		isOpen: boolean;
		idPTDProduct: number | undefined;
	}>({ isOpen: false, idPTDProduct: undefined });
	const [showDeleteVendorModal, setDeleteVendorModal] = React.useState<{
		isOpen: boolean;
		idPTDProduct: number | undefined;
		idPTDVendor: number | undefined;
	}>({ isOpen: false, idPTDProduct: undefined, idPTDVendor: undefined });

	// Table
	const vendorFormMethods = useForm<any>({
		mode: 'onSubmit',
		reValidateMode: 'onChange',
	});
	const [editTable, setEditTable] = React.useState<AssociativeTable | null>(
		null
	);
	const [isSavingRow, setIsSavingRow] = React.useState<boolean>(false);

	//Status
	const statusOptions: { value: string; label: string }[] = [
		{ value: 'Approved', label: 'Approved' },
		{ value: 'Evaluated', label: 'Evaluated' },
		{ value: 'UnderReview', label: 'Under Review' },
		{ value: 'OnHold', label: 'On Hold' },
		{ value: 'Delete', label: 'Delete' },
		{ value: 'Review', label: 'Review' },
	];

	// Memoize fetchData using useCallback
	const fetchProductsForPTDListCallback = useCallback(
		(signal) => {
			fetchProductsForPTDList(idPTDList, signal);
		},
		[idPTDList, fetchProductsForPTDList]
	);

	// Memoize fetchData using useCallback
	const fetchUserAccessCallback = useCallback(
		(signal) => {
			fetchUserAccessForPTDList(idPTDList, signal);
		},
		[idPTDList, fetchUserAccessForPTDList]
	);

	React.useEffect(() => {
		const controller = new AbortController();
		const signal = controller.signal;

		fetchProductsForPTDListCallback(signal);
		fetchUserAccessCallback(signal);
		setTimeout(() => {
			setIsLoading(false);
		}, 0);

		return () => {
			// cancel the request before component unmounts
			controller.abort();
		};
	}, [fetchProductsForPTDListCallback, fetchUserAccessCallback]);

	/// Add Product Modal
	async function addNewProduct(
		fields: Partial<PTDProductRequest>
	): Promise<boolean> {
		if (
			idPTDList !== undefined &&
			(fields.name !== undefined ||
				(fields.productsTypePickListItemIds !== undefined &&
					fields.productsTypePickListItemIds.length > 0))
		) {
			setIsLoading(true);
			const newProduct: PTDProductRequest = {
				name: fields.name,
				premiumTradeDirectoryId: idPTDList,
				productsTypePickListItemIds: fields.productsTypePickListItemIds,
				speckListItemIds: fields.speckListItemIds,
				pickListItemIds: fields.pickListItemIds,
			};

			try {
				const response: PTDProductPOSTRespone | PTDProductSpecRespone[] | null =
					await PTDAPIs.premiumTradeDirectories.AddProductToList(newProduct);
				let saveRespond: PTDProduct | null = null;

				if (response) {
					if (
						fields.productsTypePickListItemIds !== undefined &&
						fields.productsTypePickListItemIds.length > 0
					) {
						const specResponse: PTDProductSpecRespone[] =
							response as unknown as PTDProductSpecRespone[];
						const specResponseFiltered = specResponse.filter(
							(prod) => prod.pickListItemName && prod.pickListItemName !== ''
						);

						saveRespond = {
							id: specResponse[0].productId,
							premiumTradeDirectoryId: specResponse[0].premiumTradeDirectoryId,
							name: specResponse[0].productName,
							isFromSpec:
								specResponse[0].speckListItemId > 0 ||
								specResponse[0].productsTypePickListItemId > 0,
							productsTypePickListItemId:
								specResponse[0].productsTypePickListItemId,
							productSpecs: specResponseFiltered.map((spec) => ({
								pickListItemId: spec.pickListItemId,
								pickListItemName: spec.pickListItemName,
								speckListItemId: spec.speckListItemId,
								speckListItemName: spec.speckListItemName,
							})),
						};
					} else {
						const specResponse: PTDProductPOSTRespone =
							response as unknown as PTDProductPOSTRespone;
						saveRespond = {
							...specResponse,
							id: specResponse.productId,
							premiumTradeDirectoryId: specResponse.premiumTradeDirectoryId,
							name: specResponse.name,
							productsTypePickListItemId:
								specResponse.productsTypePickListItemId,
							isFromSpec:
								specResponse.speckListItemId > 0 ||
								specResponse.productsTypePickListItemId > 0,
						};
					}
				}

				if (saveRespond) {
					addProduct(saveRespond);
					toast.success('Product was added in the list');
					setAddProductModal({ isOpen: false }); // Ensure the modal is closed after submission
					return true;
				} else {
					setAddProductModal({ isOpen: false }); // Ensure the modal is closed after submission
					return false;
				}
			} catch (error) {
				toast.error('An error occurred while adding the product');
				return false;
			} finally {
				setIsLoading(false);
			}
		}

		return false;
	}
	/// Add Product Modal End

	/// Edit Product Modal
	async function editProduct(
		id: number,
		fields: Partial<PTDProductRequest>
	): Promise<boolean> {
		if (id) {
			setIsLoading(true);
			const editProduct: PTDProductPutRequest = {
				productId: id,
				name: fields.name,
				premiumTradeDirectoryId: idPTDList,
				productsTypePickListItemIds: fields.productsTypePickListItemIds,
				speckListItemIds: fields.speckListItemIds,
				pickListItemIds: fields.pickListItemIds,
				productSpecIds: fields.speckListItemIds,
			};

			try {
				const response:
					| PTDProductPUTRespone[]
					| PTDProductSpecRespone[]
					| null = await PTDAPIs.premiumTradeDirectories.UpdateProduct(
						editProduct
					);
				let saveRespond: PTDProduct | null = null;

				if (response) {
					if (
						fields.productsTypePickListItemIds !== undefined &&
						fields.productsTypePickListItemIds.length > 0
					) {
						const specResponse: PTDProductSpecRespone[] =
							response as unknown as PTDProductSpecRespone[];
						const specResponseFiltered = specResponse.filter(
							(prod) => prod.pickListItemName && prod.pickListItemName !== ''
						);

						saveRespond = {
							id: specResponse[0].productId,
							premiumTradeDirectoryId: specResponse[0].premiumTradeDirectoryId,
							name: specResponse[0].productName,
							isFromSpec:
								specResponse[0].speckListItemId > 0 ||
								specResponse[0].productsTypePickListItemId > 0,
							productsTypePickListItemId:
								specResponse[0].productsTypePickListItemId,
							productSpecs: specResponseFiltered.map((spec) => ({
								pickListItemId: spec.pickListItemId,
								pickListItemName: spec.pickListItemName,
								speckListItemId: spec.speckListItemId,
								speckListItemName: spec.speckListItemName,
							})),
						};
					} else {
						const specResponse: PTDProductPUTRespone =
							response[0] as unknown as PTDProductPUTRespone;
						saveRespond = {
							...specResponse,
							id: specResponse.productId,
							premiumTradeDirectoryId: specResponse.premiumTradeDirectoryId,
							name: specResponse.productName,
							productsTypePickListItemId:
								specResponse.productsTypePickListItemId,
							isFromSpec:
								specResponse.speckListItemId > 0 ||
								specResponse.productsTypePickListItemId > 0,
						};
					}
				}

				if (saveRespond) {
					updateProduct(saveRespond);
					toast.success('Product was edited in the list');
					setAddProductModal({ isOpen: false }); // Ensure the modal is closed after submission
					return true;
				} else {
					setAddProductModal({ isOpen: false }); // Ensure the modal is closed after submission
					return false;
				}
			} catch (error) {
				toast.error('An error occurred while editing a product');
				return false;
			} finally {
				setIsLoading(false);
			}
		}

		return false;
	}
	/// Edit Product Modal End

	// Delete product modal start
	async function onDeleteProduct(idPTDProduct: number): Promise<boolean> {
		setIsLoading(true);
		try {
			const response: string | null =
				await PTDAPIs.premiumTradeDirectories.DeleteProduct({
					productId: idPTDProduct,
				});

			if (response) {
				deleteProduct(idPTDProduct);
				toast.success(response);
				setDeleteProductModal({ isOpen: false, idPTDProduct: undefined });
				return true;
			} else {
				setDeleteProductModal({ isOpen: false, idPTDProduct: undefined });
				return false;
			}
		} finally {
			setIsLoading(false);
		}
	}
	// Delete product modal end

	/// Add Vendor
	function onClickEditOrDuplicate(
		row: any,
		type: 'edit' | 'duplicate',
		productID: number
	) {
		const keys = Object.keys(row.original);
		const nonEditable = ['validated'];
		nonEditable.forEach((val) => {
			const index = keys.indexOf(val);
			if (index > -1) {
				keys.splice(index, 1);
			}
		});
		keys.forEach((key) => {
			vendorFormMethods.setValue('specs.' + key, row.original[key]);
		});
		if (type === 'edit') {
			const newTable: AssociativeTable = {};
			newTable[productID] = {
				rowId: row.index,
				manufecId: row.original.id,
			};
			setEditTable(newTable);
		} else {
			const newTable: AssociativeTable = {};
			newTable[productID] = {
				rowId: 0,
				manufecId: 0,
				duplicateRow: row.original.id,
			};
			setEditTable(newTable);
		}
	}

	function closeVendorAddRow() {
		setEditTable(null);
		vendorFormMethods.reset({}, { keepDirty: false, keepValues: false });
	}

	async function submitVendorForm(
		fields: FieldValues,
		idPTDProduct: number,
		idPTDVendor: number
	) {
		const vendorData: PTDVendor = { ...fields.specs };
		if (!!editTable) {
			const productID = idPTDProduct as keyof typeof editTable;
			const currentTable = editTable[productID] as Table;
			if (currentTable) {
				setIsSavingRow(true);

				// set up request data
				const parts = vendorData.operatingLocation
					.split(',')
					.map((part) => part.trim());
				const city = parts[0];
				const region = parts.length >= 3 ? parts[parts.length - 2] : null;
				const country = parts[parts.length - 1];
				const vendor = {
					vendorName: organizationList?.find(
						(org) => org.id === vendorData.vendorID
					)?.organizationName,
					notes: vendorData.notes,
					operatingLocation: vendorData.operatingLocation,
					userDefinedStatus: getEnumFromVendorStatus(
						vendorData.userDefinedStatus
					),
					city: city,
					region: region,
					country: country,
				};

				if (currentTable.newLine || currentTable.duplicateRow) {
					const newVendor: PTDVendorRequest = {
						productId: idPTDProduct,
						...vendor,
					};
					try {
						const response: PTDVendor | null =
							await PTDAPIs.premiumTradeDirectories.AddVendor(newVendor);
						if (response) {
							addPTDVendor(response, idPTDProduct);
							toast.success('Vendor was added!');
							return true;
						} else {
							return false;
						}
					} finally {
						setIsSavingRow(false);
						setEditTable(null);
						vendorFormMethods.reset(
							{},
							{ keepDirty: false, keepValues: false }
						);
					}
				} else {
					const updateVendor: PTDVendorPutRequest = {
						id: idPTDVendor,
						...vendor,
					};
					try {
						const response: PTDVendor | null =
							await PTDAPIs.premiumTradeDirectories.UpdateVendor(updateVendor);
						if (response) {
							updatePTDVendor(response, idPTDProduct);
							toast.success('Vendor was updated!');
							return true;
						} else {
							return false;
						}
					} finally {
						setIsSavingRow(false);
						setEditTable(null);
						vendorFormMethods.reset(
							{},
							{ keepDirty: false, keepValues: false }
						);
					}
				}
			}
		}
	}
	/// Add Vendor

	// Delete vendor modal start
	async function onDeletePTDVendor(productId: number, idPTDVendor: number) {
		setIsLoading(true);
		try {
			const response: string | null =
				await PTDAPIs.premiumTradeDirectories.DeleteVendor(idPTDVendor);

			if (response) {
				deletePTDVendor(productId, idPTDVendor);
				toast.success(response);
				setDeleteVendorModal({
					isOpen: false,
					idPTDProduct: undefined,
					idPTDVendor: undefined,
				}); // Ensure the modal is closed after submission
				return true;
			} else {
				setDeleteVendorModal({
					isOpen: false,
					idPTDProduct: undefined,
					idPTDVendor: undefined,
				}); // Ensure the modal is closed after submission
				return false;
			}
		} finally {
			setIsLoading(false);
		}
	}
	// Delete vendor modal end

	const fetchLocationSuggestions = debounce((inputValue, callback) => {
		if (inputValue.length <= 2) {
			callback([]);
			return;
		}

		scopeMatchAPI.locationSuggestions
			.GetLocationSuggestions(inputValue)
			.then((response) => {
				const suggestions = response.map(
					(suggestion: { location: string }) => ({
						label: suggestion.location,
						value: suggestion.location,
					})
				);
				callback(suggestions);
			})
			.catch((error) => {
				console.error(error);
				callback([]);
			});
	}, 1000);

	if (isLoading === true) {
		return <LoadingPage />;
	}

	return (
		<FormProvider {...vendorFormMethods}>
			<Page title={pageTitle}>
				<div className="d-flex">
					<div className="d-flex flex-column w-100">
						<TitleBar pageTitle={pageTitle} />
					</div>
				</div>
				<div className="container-fluid mb-2 px-5 py-3">
					<div className="row">
						<div className="col-3 offset-9">
							{!accessForList ||
							accessForList.permission === 'Edit' ||
							accessForList.permission === 'Suggest' ? (
									<Button
										onClick={() => setAddProductModal({ isOpen: true })}
										loading={false}
										className="btn-primary ms-auto mb-3 w-100"
									>
										<IconAdd /> Add Product
									</Button>
								) : null}
						</div>
					</div>
					{PTDProducts?.map((product, key) => {
						const listVendors = PTDVendors[product.id]
							? [...PTDVendors[product.id]]
							: [];
						const productID = product.id as keyof typeof editTable;
						const currentTable = !!editTable
							? (editTable[productID] as Table)
							: null;

						// new row start
						const newRow: PTDVendor = {
							id: 0,
							vendorName: '',
							operatingLocation: '',
							notes: '',
							userDefinedStatus: 'Approved',
							listedBy: 0,
							listedBySector: 0,
							listedByIndusty: 0,
							productId: 0,
							createdBy: '',
							createdAt: '',
							lastModifiedAt: '',
							validatedProduct: false,
							validatedLocation: false,
							validedRowStatus: false,
						};

						if (currentTable?.newLine === true) {
							listVendors.unshift(newRow);
						}
						// new row end

						/// duplicated row start
						if (!!currentTable?.duplicateRow) {
							const row = listVendors.find((x) => {
								return x.id === currentTable.duplicateRow;
							});
							const lineItem: any = { ...row };

							lineItem['id'] = 'duplicate';

							listVendors.unshift(lineItem);
						}
						/// duplicated row end

						const columns = [
							{
								Header: 'Vendor',
								accessor: 'vendorName',
								filterable: true,
								Cell: (row: any) => {
									if (!!editTable) {
										const currentTable = editTable[productID] as Table;
										if (currentTable?.rowId === row.index) {
											return (
												<Controller
													key={'vendorID'}
													control={vendorFormMethods.control}
													defaultValue={row.value || null}
													rules={{
														required: 'This is a required field.',
													}}
													name="specs.vendorID"
													render={({ field, fieldState }) => {
														return (
															<FormGroup
																field={field}
																fieldState={fieldState}
																className="me-1 mb-0 pb-0"
															>
																<OrganizationSelect
																	id="vendorID"
																	organizationType={
																		companyProfile?.organizationType
																	}
																	vendorOrganizationType={
																		PTD?.vendorType.toLowerCase() as any
																	}
																	showAddInstitution={true}
																	name={field.name}
																	value={
																		isString(field.value)
																			? organizationList?.find(
																				(org) =>
																					org.organizationName === row.value
																			  )?.id
																			: field.value
																	}
																	setValue={vendorFormMethods.setValue}
																	onChange={field.onChange}
																	onAddInstitution={(result: OrganizationDTO) =>
																		result && addOrganization(result)
																	}
																	onMenuOpen={vendorFormMethods.clearErrors}
																	lightButtonText={'Add Vendor'}
																	className="p-1 fs-14 table-select"
																/>
															</FormGroup>
														);
													}}
												/>
											);
										}
									}

									if (row.value === undefined || row.value.length === 0) {
										return (
											<Text>
												<i className="text-muted">Can`t find name</i>
											</Text>
										);
									}
									return <Text>{row.value}</Text>;
								},
							},
							{
								Header: 'Location',
								accessor: 'operatingLocation',
								filterable: true,
								Cell: (row: any) => {
									if (!!editTable) {
										const currentTable = editTable[productID] as Table;
										if (currentTable?.rowId === row.index) {
											return (
												<Controller
													key={'operatingLocation'}
													control={vendorFormMethods.control}
													defaultValue={row.value || ''}
													rules={{
														required: 'This is a required field.',
													}}
													name="specs.operatingLocation"
													render={({ field, fieldState }) => (
														<FormGroup
															field={field}
															fieldState={fieldState}
															className="me-1 mb-4"
														>
															<AsyncSelect
																id="operating-location"
																name={field.name}
																value={{
																	label: field.value,
																	value: field.value,
																}}
																loadOptions={(inputValue, callback) =>
																	fetchLocationSuggestions(inputValue, callback)
																}
																onChange={(selectedOption) => {
																	field.onChange(
																		selectedOption ? selectedOption.value : ''
																	);
																}}
																placeholder="Please start typing your location..."
																isClearable={true}
																menuPosition="fixed"
																className="p-1 fs-14 table-select"
															/>
														</FormGroup>
													)}
												/>
											);
										}
									}
									if (row.value === undefined || row.value.length === 0) {
										return <Text></Text>;
									}
									return <Text>{row.value}</Text>;
								},
							},
							{
								Header: 'Notes',
								accessor: 'notes',
								filterable: true,
								Cell: (row: any) => {
									if (!!editTable) {
										const currentTable = editTable[productID] as Table;
										if (currentTable?.rowId === row.index) {
											return (
												<Controller
													key={'notes'}
													control={vendorFormMethods.control}
													defaultValue={row.value || ''}
													name="specs.notes"
													render={({ field, fieldState }) => (
														<FormGroup
															field={field}
															fieldState={fieldState}
															className="me-1 mb-4"
														>
															<TextArea
																id="notes"
																rows={1}
																name={field.name}
																value={field.value}
																onChange={field.onChange}
																error={!!fieldState.error?.message}
																className="p-1 fs-14"
															/>
														</FormGroup>
													)}
												/>
											);
										}
									}

									return <Text>{row.value}</Text>;
								},
							},
							{
								Header: 'Status',
								accessor: 'userDefinedStatus',
								filterable: true,
								Cell: (row: any) => {
									if (!!editTable) {
										const currentTable = editTable[productID] as Table;
										if (currentTable?.rowId === row.index) {
											return (
												<Controller
													key={'status'}
													control={vendorFormMethods.control}
													defaultValue={row.value || 'Approved'}
													rules={{
														required: 'This is a required field.',
													}}
													name="specs.userDefinedStatus"
													render={({ field, fieldState }) => (
														<FormGroup
															field={field}
															fieldState={fieldState}
															className="me-1 mb-4"
														>
															<Select
																id="status"
																name={field.name}
																value={field.value}
																options={statusOptions}
																onChange={field.onChange}
																onMenuOpen={vendorFormMethods.clearErrors}
																className="p-1 fs-14 table-select"
															/>
														</FormGroup>
													)}
												/>
											);
										}
									}
									if (row.value === undefined || row.value.length === 0) {
										return <Text></Text>;
									}
									return (
										<Text>
											{
												statusOptions.find(
													(status) => status.value === row.value
												)?.label
											}
										</Text>
									);
								},
							},
							{
								Header: 'Validated',
								accessor: 'validedRowStatus',
								filterable: true,
								Cell: (row: any) => {
									if (!!editTable) {
										const currentTable = editTable[productID] as Table;
										if (currentTable?.rowId === row.index) {
											return '';
										}
									}

									if (row.value === undefined || row.value.length === 0) {
										return <Text></Text>;
									}
									return (
										<Text>
											{row.value ? <IconCircleTick className="mx-2" /> : ''}
										</Text>
									);
								},
							},
							{
								accessor: 'id',
								show: false,
							},
							{
								Header: '',
								accessor: 'actions',
								filterable: false,
								Cell: (row: any) => {
									const currentTable = !!editTable
										? (editTable[productID] as Table)
										: null;

									const rowIsDisabled =
										!!currentTable && currentTable.rowId !== row.index;
									const isEditingCurrentRow =
										(!!currentTable && currentTable.rowId === row.index) ||
										(!!currentTable?.duplicateRow && row.index === 0);

									return (
										<ActionCell
											formMethods={vendorFormMethods}
											idPTDList={idPTDList}
											isEditingCurrentRow={isEditingCurrentRow}
											rowIsDisabled={rowIsDisabled}
											submitForm={(fields) =>
												submitVendorForm(fields, productID, row.original.id)
											}
											closeAddRow={closeVendorAddRow}
											onClickEditOrDuplicate={(action: 'edit' | 'duplicate') =>
												onClickEditOrDuplicate(row, action, productID)
											}
											openDeleteModal={() =>
												setDeleteVendorModal({
													isOpen: true,
													idPTDProduct: productID,
													idPTDVendor: row.original.id,
												})
											}
											isSavingRow={isSavingRow}
										/>
									);
								},
							},
						];

						return (
							<div
								className={classNames('row', key > 0 ? 'mt-4' : 'mt-2')}
								key={`product-${key}`}
							>
								<div className="col-12">
									<div className="bg-light border-bottom border-primary-200 px-3 py-2 d-flex align-items-center rounded-top custom-shadow">
										<h4 className="text-dark mb-0">
											{product.name?.concat(
												product.productSpecs && product.productSpecs.length > 0
													? ' | '
													: '',
												product.productSpecs
													? product.productSpecs
														.map((r) => r.pickListItemName)
														.join(' | ')
													: ''
											)}
										</h4>

										{!accessForList ||
										accessForList.permission === 'Edit' ||
										accessForList.permission === 'Suggest' ? (
												<React.Fragment>
													<Button
														onClick={() => {
															vendorFormMethods.reset(
																{},
																{ keepValues: false, keepDirty: false }
															);
															const newTable: AssociativeTable = {};
															newTable[productID] = {
																rowId: 0,
																newLine: true,
															};
															setEditTable(newTable);
														}}
														disabled={!!editTable && !!editTable[productID]}
														className="btn-hover ms-auto text-primary py-0 fs-14 fw-normal"
													>
														<IconAdd className="me-2 svg-20" />
													Add Vendor
													</Button>
													<Button
														onClick={() => {
															setAddProductModal({
																isOpen: true,
																product: { ...product },
															});
														}}
														className="btn-hover text-primary py-0 fs-14 fw-normal"
													>
														<IconEdit className="me-2 svg-20" /> Edit Product
													</Button>
													<Button
														onClick={() => {
															setDeleteProductModal({
																isOpen: true,
																idPTDProduct: productID,
															});
														}}
														className="btn-hover text-primary py-0 fs-14 fw-normal"
													>
														<IconDelete className="me-2 svg-20" /> Delete Product
													</Button>
												</React.Fragment>
											) : null}
									</div>
									<DataTable
										data={listVendors}
										columns={columns}
										filterable={true}
										sortable={true}
										resizable={true}
										noDataText="No data found."
									/>
								</div>
							</div>
						);
					})}
				</div>

				<AddProductModal
					isOpen={showAddProductModal.isOpen}
					product={showAddProductModal.product}
					onRequestClose={() => setAddProductModal({ isOpen: false })}
					addNewProduct={addNewProduct}
					editProduct={editProduct}
				/>

				<DeletePTDVendorModal
					isOpen={showDeleteVendorModal.isOpen}
					isLoading={isLoading}
					idPTDProduct={showDeleteVendorModal.idPTDProduct}
					idPTDVendor={showDeleteVendorModal.idPTDVendor}
					onRequestClose={() =>
						setDeleteVendorModal({
							isOpen: false,
							idPTDProduct: undefined,
							idPTDVendor: undefined,
						})
					}
					deletePTDVendor={onDeletePTDVendor}
				/>

				<DeleteProductModal
					isOpen={showDeleteProductModal.isOpen}
					idPTDProduct={showDeleteProductModal.idPTDProduct}
					onRequestClose={() =>
						setDeleteProductModal({ isOpen: false, idPTDProduct: undefined })
					}
					deleteProduct={onDeleteProduct}
				/>
			</Page>
		</FormProvider>
	);
}

export default PremiumTradeDirectoryListDetails;
