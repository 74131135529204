import axios, { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';

const premiumTradeDirectories = {
	GetPremiumTradeDirectories: async (signal?: AbortSignal) => {
		const response = await axios.get(`${apiUrl}/premiumtradedirectory`, {
			signal: signal
		})
			.then((data: { data: PremiumTradeDirectory[] }) => {
				return data.data;
			})
			.catch((err: any) => {
				console.error({ error: err.response.data.responseStatus.message });
				return null;
			});
		return response;
	},
	AddPremiumTradeDirectory: async (dataToSend: PremiumTradeDirectoryRequest) => {
		const response = await axios.post(`${apiUrl}/premiumtradedirectory/`, dataToSend)
			.then((data: { data: any; }) => {
				return data.data;
			})
			.catch((err: any) => {
				toast.error(err.response?.data?.responseStatus?.message || 'An error occurred while adding a Vendor List');
				return null;
			});
		return response;
	},
	UpdatePremiumTradeDirectory: async (dataToSend: PremiumTradeDirectoryRequest, premiumTradeDirectoryId: number) => {
		const response = await axios.put(`${apiUrl}/premiumtradedirectory/`, dataToSend, {
			params: {id: premiumTradeDirectoryId}
		})
			.then((data: { data: any; }) => {
				return data.data;
			})
			.catch((err: any) => {
				toast.error(err.response?.data?.responseStatus?.message || 'An error occurred while updating the Vendor List');
				return null;
			});
		return response;
	},
	DeletePremiumTradeDirectory: async (premiumTradeDirectoryId: number | null) => {
		const response = await axios.delete(`${apiUrl}/premiumtradedirectory/${premiumTradeDirectoryId}`)
			.then((data: AxiosResponse<{ message: string; }>) => {
				return data.data.message;
			})
			.catch((err: any) => {
				toast.error(err.response?.data?.responseStatus?.message || 'An error occurred while deleting the Vendor List');
				return null;
			});
		return response;
	},
	GetProductsandSpecifications: async (dataToSend: {premiumTradeDirectoryId: number}, signal?: AbortSignal) => {
		const response = await axios.get(`${apiUrl}/product/`, {
			signal: signal,
			params: dataToSend
		})
			.then((data: { data: PTDProductSpecRespone[] }) => {
				return data.data;
			})
			.catch((err: any) => {
				console.error({ error: err.response.data.responseStatus.message });
				return [];
			});
		return response;
	},
	AddProductToList: async (dataToSend: PTDProductRequest) => {
		const response = await axios.post(`${apiUrl}/product/`, dataToSend)
			.then((data: { data: PTDProductPOSTRespone | PTDProductSpecRespone[] }) => {
				return data.data;
			})
			.catch((err: any) => {
				toast.error(err.response?.data?.responseStatus?.message || 'An error occurred while adding a product');
				return null;
			});
		return response;
	},
	UpdateProduct: async (dataToSend: PTDProductPutRequest) => {
		const response = await axios.put(`${apiUrl}/product/`, dataToSend)
			.then((data: { data: PTDProductPUTRespone[] | PTDProductSpecRespone[] }) => {
				return data.data;
			})
			.catch((err: any) => {
				toast.error(err.response?.data?.responseStatus?.message || 'An error occurred while updateing the product');
				return null;
			});
		return response;
	},
	DeleteProduct: async (dataToSend: PTDProductDeleteRequest) => {
		const response = await axios.delete(`${apiUrl}/product/`, {
			params: dataToSend
		})
			.then((data: AxiosResponse<{ message: string; }>) => {
				return data.data.message;
			})
			.catch((err: any) => {
				toast.error(err.response?.data?.responseStatus?.message || 'An error occurred while deleting the product');
				return null;
			});
		return response;
	},
	GetVendors: async (dataToSend: {productId: number}, signal?: AbortSignal) => {
		const response = await axios.get(`${apiUrl}/vendor/`, {
			signal: signal,
			params: dataToSend
		})
			.then((data: { data: PTDVendor[] }) => {
				return data.data;
			})
			.catch((err: any) => {
				console.error({ error: err.response.data.responseStatus.message });
				return [];
			});
		return response;
	},
	AddVendor: async (dataToSend: PTDVendorRequest) => {
		const response = await axios.post(`${apiUrl}/vendor/`, dataToSend)
			.then((data: { data: PTDVendor; }) => {
				return data.data;
			})
			.catch((err: any) => {
				toast.error(err.response?.data?.responseStatus?.message || 'An error occurred while adding a vendor');
				return null;
			});
		return response;
	},
	UpdateVendor: async (dataToSend: PTDVendorPutRequest) => {
		const response = await axios.put(`${apiUrl}/vendor/`, dataToSend)
			.then((data: { data: PTDVendor; }) => {
				return data.data;
			})
			.catch((err: any) => {
				toast.error(err.response?.data?.responseStatus?.message || 'An error occurred while adding a vendor');
				return null;
			});
		return response;
	},
	DeleteVendor: async (vendorId: number) => {
		const response = await axios.delete(`${apiUrl}/vendor/${vendorId}`)
			.then((data: AxiosResponse<{ message: string; }>) => {
				return data.data.message;
			})
			.catch((err: any) => {
				toast.error(err.response?.data?.responseStatus?.message || 'An error occurred while deleting the product');
				return null;
			});
		return response;
	},
	GetUserAccessForList: async (dataToSend: {premiumTradeDirectoryId: number}, signal?: AbortSignal) => {
		const response = await axios.get(`${apiUrl}/userpermision/`, {
			signal: signal,
			params: dataToSend
		})
			.then((data: { data: PTDAccess[] }) => {
				return data.data;
			})
			.catch((err: any) => {
				console.error({ error: err.response.data.responseStatus.message });
				return [];
			});
		return response;
	},
	AddUserAccess: async (dataToSend: PTDAccessRequest) => {
		const response = await axios.post(`${apiUrl}/userpermision/`, dataToSend)
			.then((data: { data: PTDAccess; }) => {
				return data.data;
			})
			.catch((err: any) => {
				toast.error(err.response?.data?.responseStatus?.message || 'An error occurred while adding a vendor');
				return null;
			});
		return response;
	},
	UpdateUserAccess: async (dataToSend: Partial<PTDAccessPutRequest>) => {
		const response = await axios.put(`${apiUrl}/userpermision/`, dataToSend)
			.then((data: { data: PTDAccess; }) => {
				return data.data;
			})
			.catch((err: any) => {
				toast.error(err.response?.data?.responseStatus?.message || 'An error occurred while updateing the product');
				return null;
			});
		return response;
	},
	DeleteUserAccess: async (accessId: number) => {
		const response = await axios.delete(`${apiUrl}/userpermision/${accessId}`)
			.then((data: AxiosResponse<{ message: string; }>) => {
				return data.data.message;
			})
			.catch((err: any) => {
				toast.error(err.response?.data?.responseStatus?.message || 'An error occurred while deleting the access');
				return null;
			});
		return response;
	},
};

const apiUrl = process.env.REACT_APP_API_URL;
const PTDAPIs = {
	premiumTradeDirectories
};

export default PTDAPIs;